<template>
    <centered-layout>
        <page-message :logo-path="clientLogo">
            Page not found.
        </page-message>
    </centered-layout>
</template>

<script>
    import {mapGetters} from 'vuex'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import PageMessage from '@/components/PageMessage'

    export default {
        name: 'NotFoundPage',
        metaInfo: {
            title: 'Page Not Found'
        },
        components: {
            CenteredLayout,
            PageMessage
        },
        computed: {
            ...mapGetters('auth', ['clientLogo'])
        }
    }
</script>
